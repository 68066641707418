
import Vue from 'vue';
import { BoxStoreComparison } from '@/components/Box';

export default Vue.extend({
  components: { BoxStoreComparison },
  mounted() {
    Vue.prototype?.$mixpanel?.track('location_comparison_report', {
      referrer: document.referrer
    });
  }
});
